import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector as rawUseSelector } from "react-redux";
import {modelSlice} from "./slices/model";

export const store = configureStore({
    reducer: {
        model: modelSlice.reducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;

import {faAngleLeft} from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";

const BackButton = () => {
  return (<Link to="/" style={{textDecoration: 'none'}}>
    <Button className="my-2">
      <FontAwesomeIcon icon={faAngleLeft} className="pe-2"/>
        Back
    </Button>
  </Link>)
}

export default BackButton

import axios from "axios";
import {gitHubIssueURLTimeout, inpaintingTimeout} from "./config/config";
import {FeedbackType} from "./types";
import {fileToBase64, imgFileToHTMLImageElement, longestMaxSize} from "./utils/general";

export const cutBase64MetaData = (x: string) => x.split(",")[1];


export const getInpaintedImageFromAPI = async (imageFile: File, mask: string, size: number, addLogo: boolean) => {
  const image = await imgFileToHTMLImageElement(imageFile)
  let base64image;

  if (Math.max(image.height, image.width) > size) {
    const resizedImageFile = await longestMaxSize(image, size, imageFile.name)
    base64image = cutBase64MetaData(await fileToBase64(resizedImageFile));
  } else {
    base64image = cutBase64MetaData(await fileToBase64(imageFile));
  }
  let body = {image: base64image, mask: cutBase64MetaData(mask)};
  if (addLogo) { // @ts-ignore
    body = {...body, add_logo: "TRUE"};
  }

  try {
    return getInpaintedImage(body, size);
  } catch (error: any) {
    throw Error(`Network error${error}`);
  }
};

const getInpaintedImage = async (body: any, size: number) => {
  let X_API_KEY;
  let X_API;

  switch (size) {
    case 768: {
      X_API_KEY = process.env.REACT_APP_INPAINTING_API_KEY_768!
      X_API = process.env.REACT_APP_INPAINTING_API_768!;
    }
      break
    case 512: {
      X_API_KEY = process.env.REACT_APP_INPAINTING_API_KEY_512!
      X_API = process.env.REACT_APP_INPAINTING_API_512!;
    }
      break
    default: {
      X_API_KEY = process.env.REACT_APP_INPAINTING_API_KEY_FOURIER!
      X_API = process.env.REACT_APP_INPAINTING_API_FOURIER!;
    }
  }

  try {
    const response = await axios.post(X_API, body, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": X_API_KEY,
      },
      timeout: inpaintingTimeout * 1000,
    });
    if (size === 1280) {
      const result = response.data.body.slice(1, -1)
      return (result)
    }
    return response.data;
  } catch (error: any) {
    throw Error(`Network error${error}`);
  }
}

export const createGitHubIssue = async (feedback: FeedbackType) => {
  const body = `  
  * **date**: ${feedback.date}
  
  message:
  ------------------------
  ${feedback.message}
  `;

  await axios.post(
    process.env.REACT_APP_GITHUB_ISSUE_API!,
    {
      title: feedback.title,
      body,
    },
    {
      headers: {
        Accept: "application/vnd.github.v3+json",
      },
      auth: {
        username: process.env.REACT_APP_GITHUB_LOGIN!,
        password: process.env.REACT_APP_GITHUB_PASSWORD!,
      },
      timeout: gitHubIssueURLTimeout * 1000,
    },
  );
};

import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface modelState {
  index: number
}

export const initialState: modelState = {
  index: 0
};

export const modelSlice = createSlice({
  name: "model",
  initialState,
  reducers: {
    change(state, action: PayloadAction<number>) {
      state.index = action.payload;
    },
  },
});

export const modelActions = modelSlice.actions;

export default modelActions;

import {Backdrop} from "@mui/material";
import React from "react";
import LoadingSpinner from "./LoadingSpinner";

interface BackDropProps {
  showBackDrop: boolean;
}

const BackDropBlock: React.FC<BackDropProps> = ({showBackDrop}) => {
  return (<Backdrop
      sx={{color: '#fff', zIndex: 35001}}
      open={showBackDrop}
  >
      <LoadingSpinner/>
  </Backdrop>
)
}

export default BackDropBlock;

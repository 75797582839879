import {Grid} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {Button, Container} from "react-bootstrap";
// @ts-ignore
import CanvasDraw from "react-canvas-draw";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import BackButton from "../components/BackButton";
import BackDropBlock from "../components/BackDropBlock";
import LoadingSpinner from "../components/LoadingSpinner";
import ParameterBlock from "../components/ParameterBlock";
import {imageFitFactor, maxRadius, minRadius, modelMapping} from "../config/config";
import {getInpaintedImageFromAPI} from "../requests";
import {useSelector} from "../store";
import modelSelector from "../store/selectors/model";
import modelActions from "../store/slices/model";
import {
    addHeader,
    base64ToHTMLImageElement,
    base64toJPG,
    getSizeFitScreen,
    imgFileToHTMLImageElement,
    resizeBase64Image,
    resizeImage2Image
} from "../utils/general";

const Inpaint = () => {
    const canvasRef = useRef<CanvasDraw>(null)
    const location = useLocation()

    const [inpaintedBase64ForDownload, setInpaintedBase64ForDownload] = useState<string>()

    const [fitScreenImage, setFitScreenImage] = useState<HTMLImageElement>()

    const [isLoading, setIsLoading] = useState(true)
    const [inpainted, setInpainted] = useState(false)
    const [fitScreenSize, setFitScreenSize] = useState({width: 512, height: 512})
    const [showBackDrop, setShowBackDrop] = useState(false)
    const [previousImageFile, setPreviousImageFile] = useState<File | null>(null)

    const dispatch = useDispatch()

    const modelType = useSelector(modelSelector)

    // @ts-ignore
    const [imageFile, setImageFile] = useState(location.state["imageFile"] as File)

    const [radius, setRadius] = useState<number | string | Array<number | string>>(
        10,
    );

    const onDownload = async () => {
        const link = document.createElement("a");
        link.download = imageFile.name.split(".")[0] + "_inpaint_photos.jpg";
        link.href = inpaintedBase64ForDownload!
        link.click();
    };

    const inpaintHandler = async () => {
        setShowBackDrop(true)
        setInpainted(false)

        const maskBase64 = canvasRef.current!.getDataURL()

        const result = addHeader(await getInpaintedImageFromAPI(imageFile!, maskBase64, modelMapping[modelType].resolution, false))

        setShowBackDrop(false)
        setInpaintedBase64ForDownload(result)

        const inpaintedImageFileResizedBase64 = await resizeBase64Image(result, fitScreenSize.width, fitScreenSize.height)

        setFitScreenImage(await base64ToHTMLImageElement(inpaintedImageFileResizedBase64))
        setPreviousImageFile(imageFile)
        setImageFile(await base64toJPG(result, imageFile.name))
        canvasRef.current!.eraseAll()

        setInpainted(true)
    }

    const handleBrushSliderChange = (event: Event, newValue: number | number[]) => {
        setRadius(newValue);
    };

    const handleBlur = () => {
        if (radius < minRadius) {
            setRadius(minRadius);
        } else if (radius > maxRadius) {
            setRadius(radius);
        }
    };

    const handleRadiusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadius(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleSliderModelChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === "number") dispatch(modelActions.change(newValue));
    };

    useEffect(() => {
        const init = async () => {
            const tempImage = await imgFileToHTMLImageElement(imageFile)

            const tempFitScreenSize = await getSizeFitScreen(tempImage.width, tempImage.height, imageFitFactor)

            const fitScreenImage = await resizeImage2Image(tempImage, tempFitScreenSize.width, tempFitScreenSize.height)

            setFitScreenSize(tempFitScreenSize)
            setFitScreenImage(fitScreenImage)
        }
        init().then(() => setIsLoading(false))
    }, [imageFile])

    const undoHandler = () => {
        setImageFile(previousImageFile!)
        setPreviousImageFile(null)
    }

    if (isLoading) return <LoadingSpinner/>

    return (
        <>
            <Container>
                <BackButton/>
                <Grid container spacing={1}>
                    <Grid item>
                        <div onTouchEnd={inpaintHandler} onMouseUp={inpaintHandler}>
                            <CanvasDraw imgSrc={fitScreenImage!.src} canvasWidth={fitScreenSize.width}
                                        canvasHeight={fitScreenSize.height}
                                        ref={canvasRef} brushRadius={radius} hideGrid lazyRadius={0}
                                        brushColor="rgba(255, 0, 0, 0.5)"/>
                        </div>
                    </Grid>
                    <Grid item xs>
                        <ParameterBlock handleBlur={handleBlur} handleBrushSliderChange={handleBrushSliderChange}
                                        handleRadiusChange={handleRadiusChange}
                                        modelType={modelType} radius={radius}
                                        handleSliderModelChange={handleSliderModelChange}
                        />
                    </Grid>
                </Grid>
                {previousImageFile && <Button onClick={undoHandler} variant="danger" className="mt-3 me-3">Undo</Button>}
                {inpainted && <Button onClick={onDownload} variant="primary" className="mt-3">Download</Button>}
            </Container>
            <BackDropBlock showBackDrop={showBackDrop}/>
        </>
    )
}

export default Inpaint;

import React from "react";
import {Container} from "react-bootstrap";
import NavBarComponent from "../components/NavBarComponent";
import MainUploadSection from "../components/MainUploadSection";
import UseCases from "../components/UseCases";

const Upload = () => {
    return (
        <>
            <NavBarComponent/>
            <MainUploadSection/>
            <section className="py-5" id="use_cases" style={{backgroundColor: "#f8f9fa"}}>
                <Container className="pb-5">
                    <UseCases/>
                </Container>
            </section>
        </>
    )
}

export default Upload

import {Formik, FormikValues} from "formik";
import React from "react";
import {Button, Container, Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import BackButton from "./BackButton";
import {createGitHubIssue} from "../requests";

const validationSchema = yup.object().shape({
    email: yup.string().email().required("Please enter your email."),
    message: yup.string().required("Please enter your feedback."),
});

const Feedback = () => {
    const navigate = useNavigate()

    const submitHandler = async (values: FormikValues) => {
        const today = new Date();
        const text = values.message.trim()

        if (text.length === 0) return

        const message = {
            date: today.toString(),
            email: values.email,
            title: `User feedback: ${values.email} ${today.toString()}`,
            message: text,
        };

        await createGitHubIssue(message);
        navigate("/")
    };

    return (
        <>
            <Container>
                <BackButton/>
                <Formik
                    onSubmit={submitHandler}
                    initialValues={{
                        email: "",
                        message: "",
                    }}
                    validationSchema={validationSchema}
                >
                    {({handleSubmit, handleChange, values, touched, errors}) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="validationFormik01" className="mt-2">
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="john.smith@gmail.com"
                                    value={values.email}
                                    onChange={handleChange}
                                    isValid={touched.email && !errors.email}
                                />
                                {errors.email && touched.email ?
                                    <p className="text-danger">Please enter a valid email.</p> : null}
                            </Form.Group>
                            <Form.Group controlId="validationFormik02" className="mt-2">
                                <Form.Control
                                    as="textarea"
                                    name="message"
                                    rows={10}
                                    cols={300}
                                    placeholder="Complains. Proposals. Any other type of the feedback."
                                    value={values.message}
                                    onChange={handleChange}
                                    isValid={touched.message && !errors.message}
                                />
                                {errors.message && touched.message ?
                                    <p className="text-danger">Please enter your feedback or a proposal.</p> : null}
                            </Form.Group>
                            <Button variant="primary" className="mt-3" type="submit">Submit</Button>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    )
}

export default Feedback

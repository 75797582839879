import {Box} from "@mui/material";
import React, {ChangeEvent} from "react";
import BrushSlider from "./BrushSlider";
import ModelSlider from "./ModelSlider";

interface ParameterBlockProps {
    radius: string | number | (string | number)[];
    handleBrushSliderChange: (event: Event, newValue: number | number[]) => void;
    handleRadiusChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleBlur: () => void;
    modelType: number;
    handleSliderModelChange: (event: Event, newValue: number | number[]) => void;
}

const ParameterBlock: React.FC<ParameterBlockProps> = ({
                                                           radius,
                                                           handleBrushSliderChange,
                                                           handleBlur,
                                                           modelType,
                                                           handleSliderModelChange,
                                                           handleRadiusChange,

                                                       }) => {
    return (
        <>
            <Box sx={{display: "flex", flexWrap: 'wrap', pt: 0, justifyContent: 'space-evenly'}}>
                <ModelSlider modelType={modelType} handleSliderModelChange={handleSliderModelChange}/>
                <BrushSlider radius={radius} handleBrushSliderChange={handleBrushSliderChange} handleBlur={handleBlur}
                             handleRadiusChange={handleRadiusChange}/>
            </Box>
        </>
    )
}

export default ParameterBlock

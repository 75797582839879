import {Button} from "@mui/material";
import React, {ChangeEvent} from "react";
import {Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const MainUploadSection = () => {
  const navigate = useNavigate()

  const changeHandler = async (event: ChangeEvent) => {
    const imageFile = (event.target as HTMLInputElement).files![0];

    navigate("/inpaint", {
      state: {
        imageFile
      }
    })
  }
  return (<section className="py-5" id="home">
      <div style={{height: "100px"}} />
      <div className="container position-relative">
        <Row className="align-items-center py-8">
          <div className="col-md-5 col-lg-6 order-md-1 text-center text-md-end">
            <video width="100%" className="rounded-3 overflow-hidden" autoPlay loop playsInline muted controls>
              <source src="/assets/example.mp4" type="video/mp4"/>
            </video>
          </div>
          <div className="col-md-7 col-lg-6 text-center text-md-start">
            <h1 className="mb-4 display-3 fw-bold lh-sm">Use AI to fix<br
              className="d-block d-lg-none d-xl-block" />your images!</h1>
            <p className="mt-3 mb-4 fs-1">Remove any object, defect, people or text from your images in
              seconds!</p>
            <Button variant="contained" component="label" size="large">
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={(event) => changeHandler(event)}
              />
              Upload image
            </Button>
          </div>
        </Row>
      </div>
    </section>
  )
}

export default MainUploadSection

import {FC} from "react";
import Image from 'react-bootstrap/Image'


interface BeforeAfterImagesProps {
    src1: string;
    src2: string;
}

const BeforeAfterImages: FC<BeforeAfterImagesProps> = ({src1, src2}) => {
    return (
        <div className="d-flex justify-content-center">
            <div>
                <Image src={src1} className="rounded-3 px-3" fluid height={341}/>
            </div>
            <div>
                <Image src={src2} className="rounded-3 px-3" fluid height={341}/>
            </div>
        </div>
    )
}

export default BeforeAfterImages;

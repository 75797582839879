import React, {FC} from "react";
import {BrowserRouter} from "react-router-dom";
import {store} from "./store";
import { Provider } from 'react-redux'

const AppWrapper: FC<{ Insider: any }> = ({Insider}) => {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <Insider/>
                </BrowserRouter>
            </Provider>

        </React.StrictMode>
    );
};

export default AppWrapper;

import React from "react";
import {Carousel} from "react-bootstrap";
import BeforeAfterImages from "./BeforeAfterImages";
import {imagePairs} from "../config/config";

const UseCases = () => {
    return (
        <>
            <div className="text-center pb-3"><h1 className="display-4 fw-bold lh-sm">Use Cases</h1></div>
            <Carousel variant="dark">
                {imagePairs.map(element => <Carousel.Item  interval={1500} title={element.label} key={element.label}>
                    <BeforeAfterImages
                        src1={element.src1} src2={element.src2}/>
                </Carousel.Item>)}
            </Carousel>
        </>
    )
}

export default UseCases;

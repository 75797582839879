import {Container, Nav, Navbar} from "react-bootstrap";

import {useNavigate} from "react-router-dom";


const NavBarComponent = () => {
    const navigate = useNavigate()
    const feedbackHandler = () => {
        navigate("/feedback")
    }
    return (
        <Navbar expand="lg" bg="light" fixed="top" className="py-3" data-navbar-on-scroll="data-navbar-on-scroll">
            <Container>
                <Navbar.Brand className="navbar-brand d-flex align-items-center fw-bold fs-2" href="/">
                    <div className="text-warning">Inpaint</div>
                    <div className="text-1000">.Photos</div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarSupportedContent"/>
                <Navbar.Collapse className="border-lg-0 mt-4 mt-lg-0" id="navbarSupportedContent">
                    <Nav className="ms-auto pt-2 pt-lg-0">
                        <Nav.Link className="nav-item" data-anchor="data-anchor" onClick={feedbackHandler}>Contact
                            Support</Nav.Link>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
export default NavBarComponent;
import {Route, Routes} from "react-router-dom";
import Feedback from "./components/Feedback";
import Inpaint from "./pages/Inpaint";
import Upload from "./pages/Upload";

const App = () => {
    return (
        <Routes>
            <Route path="/" element={<Upload/>}/>
            <Route path="/feedback" element={<Feedback/>}/>
            <Route path="/inpaint" element={<Inpaint/>}/>
        </Routes>
    )
}

export default App;

import {Box, Slider, Typography} from "@mui/material";
import React from "react";
import {modelMapping} from "../config/config";

interface ModelSliderProps {
    modelType: number;
    handleSliderModelChange: (event: Event, newValue: number | number[]) => void;
}

const ModelSlider: React.FC<ModelSliderProps> = ({modelType, handleSliderModelChange}) => {

    const marks = [{value: 0, label: modelMapping[0].label}, {value: 1, label: modelMapping[1].label}, {
        value: 2,
        label: modelMapping[2].label
    }]

    return (
        <Box sx={{width: 80, justifyContent: 'center'}}>
            <Typography id="model-slider" gutterBottom>
                Model
            </Typography>
            <Slider
                value={modelType}
                valueLabelDisplay="auto"
                step={1}
                min={0}
                max={marks.length - 1}
                onChange={handleSliderModelChange}
                marks={marks}
            />
        </Box>
    )
}

export default ModelSlider

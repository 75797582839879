import BrushIcon from "@mui/icons-material/Brush";
import {Box, Grid, Input, Slider} from "@mui/material";
import React, {ChangeEvent} from "react";
import {maxRadius, minRadius} from "../config/config";

interface BrushSliderProps { radius: string | number | (string | number)[];
  handleBrushSliderChange: (event: Event, newValue: number | number[]) => void;
  handleRadiusChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur:  () => void;}

const BrushSlider: React.FC<BrushSliderProps> = ({radius, handleBrushSliderChange, handleBlur, handleRadiusChange}) => {
  return (<Box sx={{width: 150}}>
        <BrushIcon />
    <Grid container spacing={1} alignItems="center">
      <Grid item xs>
        <Slider
          value={typeof radius === 'number' ? radius : minRadius}
          onChange={handleBrushSliderChange}
          aria-labelledby="input-slider"
          min={minRadius}
          max={maxRadius}
        />
      </Grid>
      <Grid item>
        <Input
          value={radius}
          size="small"
          onChange={handleRadiusChange}
          onBlur={handleBlur}
          inputProps={{
            step: 5,
            min: minRadius,
            max: maxRadius,
            type: 'number',
            // 'aria-labelledby': 'input-slider',
          }}
        />
      </Grid>
    </Grid>
  </Box>)
}

export default BrushSlider;

export const gitHubIssueURLTimeout = 300;

export const jpegQuality = 0.8;
export const inpaintingTimeout = 300;
export const minRadius = 5;
export const maxRadius = 50;

export const modelMapping = [{resolution: 512, label: "512p"}, {resolution: 768, label: "768p"}, {resolution: 1280, label: "1280p"}]

export const imageFitFactor = 0.7;

export const imagePairs = [
  {
    label: "Photo bomb",
    src1: "/examples/photo_bomb_before.jpg",
    src2: "/examples/photo_bomb_after.jpg"
  },
  {
    label: "Watermark",
    src1: "/examples/watermark_before.jpg",
    src2: "/examples/watermark_after.jpg",
  },
  {
    label: "Restoration",
    src1: "/examples/image_restoration_before.jpg",
    src2: "/examples/image_restoration_after.jpg"
  },
  {
    label: "Unwanted object",
    src1: "/examples/trash_before.jpg",
    src2: "/examples/trash_after.jpg"
  }
]

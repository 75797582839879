import {jpegQuality} from "../config/config";

const pica = require("pica")();

export const fileToBase64 = (inputFile: File): Promise<string> =>
    new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = () => {
            reader.abort();
            reject(new DOMException("Problem parsing input file."));
        };
        reader.onload = () => resolve(reader.result as string);
        reader.readAsDataURL(inputFile);
    });

export const imgFileToHTMLImageElement = async (inputFile: File): Promise<HTMLImageElement> => {
    const base64data = await fileToBase64(inputFile);
    return new Promise((resolve) => {
        const image = new Image();
        image.onload = () => {
            resolve(image);
        };
        image.src = base64data;
    });
};

export const base64ToHTMLImageElement = async (base64data: string): Promise<HTMLImageElement> => {
    return new Promise((resolve) => {
        const image = new Image();
        image.onload = () => {
            resolve(image);
        };
        image.src = base64data;
    });
};

export const longestMaxSize = async (image: HTMLImageElement, maxSize: number, fileName: string): Promise<File> => {
    let width;
    let height;
    if (image.width < image.height) {
        width = Math.round((maxSize * image.width) / image.height);
        height = maxSize;
    } else {
        height = Math.round((maxSize * image.height) / image.width);
        width = maxSize;
    }

    const resizedCanvas = document.createElement("canvas");
    resizedCanvas.height = height;
    resizedCanvas.width = width;

    const temp = async () =>
        new Promise<Blob>((resolve) =>
            pica
                .resize(image, resizedCanvas)
                .then((result: any) => resolve(pica.toBlob(result, "image/jpeg", jpegQuality))),
        );
    const myBlob = await temp();

    return new File([myBlob], fileName, {
        type: myBlob.type,
    });
};

export const resizeImage2File = async (image: HTMLImageElement, width: number, height: number, fileName: string): Promise<File> => {
    const resizedCanvas = document.createElement("canvas");
    resizedCanvas.height = height;
    resizedCanvas.width = width;

    const temp = async () =>
        new Promise<Blob>((resolve) =>
            pica
                .resize(image, resizedCanvas)
                .then((result: any) => resolve(pica.toBlob(result, "image/jpeg", jpegQuality))),
        );
    const myBlob = await temp();

    return new File([myBlob], fileName, {
        type: myBlob.type,
    });
}
export const resizeImage2Image = async (image: HTMLImageElement, width: number, height: number) => imgFileToHTMLImageElement(await resizeImage2File(image, width, height, "temp.png"))

export const resizeBase64Image = async (base64data: string, width: number, height: number) => {
    const image = await base64ToHTMLImageElement(base64data)
    const resizedImageFile = await resizeImage2File(image, width, height, "temp.png")
    return await fileToBase64(resizedImageFile)
    }

export const addHeader = (x: string) => `data:image/jpg;base64,${x}`

export const getSizeFitScreen = async (imageWidth: number, imageHeight: number, factor: number) => {
    let newWidth;
    let newHeight;

    const aspectRatio = window.innerWidth / window.innerHeight;

    const imageRatio = imageWidth / imageHeight

    if (aspectRatio < imageRatio) {
        newWidth = window.innerWidth;
        newHeight = window.innerWidth / imageRatio;
    } else {
        newWidth = window.innerHeight * imageRatio;
        newHeight = window.innerHeight;
    }

    return {width: newWidth * factor, height: newHeight * factor}
}

export const base64toFile = async (dataUrl: string, filename: string, type: string): Promise<File> => {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], filename, { type });
};

export const base64toJPG = async (dataUrl: string, filename: string): Promise<File> => base64toFile(dataUrl, filename, "image/jpeg")
export const base64toPNG = async (dataUrl: string, filename: string): Promise<File> => base64toFile(dataUrl, filename, "image/png")
